<template>
  <div
    class="
      container-fluid
      p-0
      fullscreen
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <main class="form-signin">
      <slot />
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthLayout",
});
</script>
