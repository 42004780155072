<template>
  <AuthLayout>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <h1 class="h3 mb-3 fw-bold text-center">
        <i class="bi bi-person display-3"></i><br />
        GİRİŞ
      </h1>

      <div class="form-floating">
        <Field
          name="email"
          v-model="email"
          as="input"
          type="email"
          class="form-control rounded-0 rounded-top"
          :class="{ 'is-invalid': errors.email }"
          id="floatingEmail"
          placeholder="Eposta adresiniz"
        />
        <label for="floatingEmail">Eposta Adresiniz</label>
        <div class="invalid-feedback text-left">{{ errors.email }}</div>
      </div>
      <div class="form-floating">
        <Field
          name="password"
          v-model="password"
          as="input"
          type="password"
          class="form-control rounded-0 rounded-bottom"
          :class="{ 'is-invalid': errors.password }"
          id="floatingPassword"
          placeholder="Parola"
        />
        <label for="floatingPassword">Parola</label>
        <div class="invalid-feedback">{{ errors.password }}</div>
      </div>
      <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">
        Giriş Yap
      </button>
    </Form>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/Auth.layout.vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";

import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  components: { AuthLayout, Form, Field },
  methods: {
    ...mapActions("account", ["login"]),

    onSubmit(values) {
      this.login({ user: values });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("Yanlış eposta formatı!")
        .required("Zorunlu alan!"),
      password: Yup.string()
        .min(8, "En az 8 karakter olmalıdır!")
        .required("Zorunlu alan!"),
    });

    return {
      schema,
    };
  },
};
</script>

<style></style>
